// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-ativacao-js": () => import("./../../../src/pages/ativacao.js" /* webpackChunkName: "component---src-pages-ativacao-js" */),
  "component---src-pages-cadastro-index-js": () => import("./../../../src/pages/cadastro/index.js" /* webpackChunkName: "component---src-pages-cadastro-index-js" */),
  "component---src-pages-condicoes-especiais-js": () => import("./../../../src/pages/condicoes-especiais.js" /* webpackChunkName: "component---src-pages-condicoes-especiais-js" */),
  "component---src-pages-download-js": () => import("./../../../src/pages/download.js" /* webpackChunkName: "component---src-pages-download-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-modelos-js": () => import("./../../../src/pages/modelos.js" /* webpackChunkName: "component---src-pages-modelos-js" */),
  "component---src-pages-planos-js": () => import("./../../../src/pages/planos.js" /* webpackChunkName: "component---src-pages-planos-js" */),
  "component---src-pages-politica-privacidade-js": () => import("./../../../src/pages/politica-privacidade.js" /* webpackChunkName: "component---src-pages-politica-privacidade-js" */),
  "component---src-pages-suporte-js": () => import("./../../../src/pages/suporte.js" /* webpackChunkName: "component---src-pages-suporte-js" */),
  "component---src-pages-termos-de-uso-js": () => import("./../../../src/pages/termos-de-uso.js" /* webpackChunkName: "component---src-pages-termos-de-uso-js" */)
}

