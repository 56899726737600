import React from 'react';
import { ModalProvider } from 'containers/ModalProvider';
import { reducer, initialValues } from 'containers/ModalRoot/module';
import { theme, GlobalStyle } from 'config-styles';
import { ThemeProvider } from 'styled-components';

export const wrapRootElement = ({ element }) => (
  <ThemeProvider theme={theme}>
    <GlobalStyle />
    <ModalProvider reducer={reducer} initialState={initialValues}>{element}</ModalProvider>
  </ThemeProvider>
)
