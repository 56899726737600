import { createGlobalStyle } from "styled-components";

/**
 * Cria o estilo global das páginas
 * @constructor
 */
export const GlobalStyle = createGlobalStyle`
  .grecaptcha-badge {
    display: none !important;
    opacity: 0;
    pointer-events: none;
  }
  
  * {
    margin: 0;
    padding: 0;
    outline: none;
    box-sizing: border-box;
    
    &:after, &:before {
      box-sizing: border-box;
    }
  }
  
  ul {
    list-style: none;
  }
  
  button, a {
    cursor: pointer;
  }
   
  html {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: 'Barlow', sans-serif;
    font-weight: 500;
    width: 100vw;
    height: initial;
  }
  
  #gatsby-focus-wrapper {
    width: 100%;
    height: 100%;
    min-height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    position: relative;
  }
  
  html,body {
    width: 100%;
    font-size: 14px;
  }
  
  [data-tooltip] {
    display: inline-block;
    position: relative;
    
    &::after {
      pointer-events: none;
      padding: 10px 15px;
      text-align: center;
      content: attr(data-tooltip);
      font-size: 12px;
      line-height: 18px;
      position: absolute;
      background: #272727;
      border-radius: 4px;
      color: #fff;
      white-space: pre; /* or pre-wrap */
      opacity: 0;
      visibility: hidden;
      transition: opacity .2s, visibility .2s;
      will-change: opacity, visibility;
    }
    
    &::before {
      content: '';
      position: absolute;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      transform: translateX(-50%) scale(1);
      left: 50%;
      opacity: 0;
      visibility: hidden;
      transition: opacity .2s, visibility .2s;
      will-change: opacity, visibility;
    }
    
    &:hover::after,
    &:hover::before {
      opacity: 1;
      visibility: visible;
    }
  }
  
  [data-position="top"]::after {
    transform: translateX(-50%) scale(1);
    left: 50%;
    bottom: calc(100% + 8px);
  }
  
  [data-position="top"]::before {
    border-top: 8px solid #272727;
    bottom: 100%;
  }
  
  [data-position="bottom"]::after{
    transform: translateX(-50%) scale(1);
    left: 50%;
    top: calc(100% + 8px);
  }
  
  [data-position="bottom"]::before {
    border-bottom: 8px solid #272727;
    top: 100%;    
  }
  
  .slick-slide {
    display: none;
    float: left;
    height: 100%;
    min-height: 1px;
  }
`;

const DESKTOP = 1124;
const TABLET = 768;
const MOBILE = 468;

export const Breakpoints = {
  desktop: `@media all and (min-width: ${DESKTOP}px)`,
  desktopMax: `@media all and (max-width: ${DESKTOP}px)`,

  tablet: `
    @media all and (min-width: ${MOBILE}px) and (max-width: ${TABLET}px)
  `,
  tabletMin: `@media all and (min-width: ${TABLET + 1}px)`,
  tabletMax: `@media all and (max-width: ${TABLET}px)`,

  mobile: `@media all and (max-width: ${MOBILE}px)`,
  mobileMin: `@media all and (min-width: ${MOBILE + 1}px)`,
};

// Base
export const baseBorderRadius = "4px";

// Colors
export const colors = {
  white: "#fff",
  primary: "#FD9526",
  secondary: "#2B343E",
  success: "#01AFAD",
  tertiary: "#0876ea",
  error: "#D74444",
  whatsapp: "#25d366",
};

export const theme = {
  baseWidth: "1180px",
  base: {
    borderRadius: baseBorderRadius,
  },
  colors,
  button: {
    height: "45px",
    fontSize: "16px",
    borderRadius: baseBorderRadius,
  },
  input: {
    height: "45px",
    borderRadius: "2px",
  },
  textarea: {
    height: "90px",
  },
  zIndex: {
    header: "1000",
    modal: "2000",
  },
  breakpoints: Breakpoints,
};
