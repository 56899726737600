/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
export { wrapRootElement } from "./scripts/wrap-provider";
require("typeface-barlow");
require("slick-carousel/slick/slick.css");

//const script = `
//  var Tawk_API=Tawk_API||{}, Tawk_LoadStart=new Date();
//(function(){
//var s1=document.createElement("script"),s0=document.getElementsByTagName("script")[0];
//s1.async=true;
//s1.src='https://embed.tawk.to/57bc9a5bb17dda65c4c3ec3d/default';
//s1.charset='UTF-8';
//s1.setAttribute('crossorigin','*');
//s0.parentNode.insertBefore(s1,s0);
//})();
//`;
//
//const addScript = (content) => {
//  const script = document.createElement("script");
//  script.async = true;
//  script.innerHTML = content;
//  document.body.appendChild(script);
//};

export const onClientEntry = () => {
  window.onload = () => {
    setTimeout(() => {
      //addScript(script);
    }, 4000);
  };
};
