import React, { createContext, useContext, useReducer } from "react";

// Cria o contexto da modal
export const ModalContext = createContext();

// Cria o provider
export const ModalProvider = ({ reducer, initialState, children }) => (
  <ModalContext.Provider value={useReducer(reducer, initialState)}>
    {children}
  </ModalContext.Provider>
);

export const useModalState = () => useContext(ModalContext) || [];
